import React, { useMemo, useState } from "react"
import { Form, Modal, Tooltip } from "react-bootstrap"
import { FaTrash } from "react-icons/fa"
import CopyIDIcon from "../../../../components/commons/CopyIDIcon"
import {
  selectData,
  deleteUserInData,
  IUsers,
  updateUserInData,
} from "../../../../redux/data"
import { selectActiveAccount } from "../../../../redux/activeAccount"
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks"
import * as S from "../../../../styles/styles"
import renderColoredSpan from "../../../../utils/renderColoredSpan"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { deleteUser, updateUserNotification } from "../../../../services/User"
import SpinnerComponent from "../../../../components/commons/SpinnerComponent"
import ReelerTooltip from "../../../../components/commons/tooltip"

export const CustomerUserTable = () => {
  const dispatch = useAppDispatch()

  const { users } = useAppSelector(selectData)
  const activeAccount = useAppSelector(selectActiveAccount)

  const filteredAccounts = useMemo(() => {
    const result =
      users &&
      users.filter(user => {
        if (
          user.accounts &&
          Array.isArray(user.accounts) &&
          user.accounts.includes(activeAccount.id)
        ) {
          return user
        } else return undefined
      })
    return result
  }, [users, activeAccount])

  interface NotificationProps {
    user: IUsers
  }
  const ToggleUserNotfication = ({ user }: NotificationProps) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleToggleNotification = (user: IUsers) => {
      setLoading(true)
      // Toggle notification

      let usrObjToUpdate = {
        ...user,
        notifications: {
          ...user.notifications,
          weeklyNotification: !user?.notifications?.weeklyNotification,
        },
      }

      // Update firestore
      updateUserNotification(
        user.id,
        usrObjToUpdate.notifications.weeklyNotification
      )
        .then(() => {
          // Update redux
          dispatch(updateUserInData(usrObjToUpdate))
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
    }

    if (loading) {
      return <SpinnerComponent />
    }

    return (
      <Form.Check
        type="checkbox"
        checked={user?.notifications?.weeklyNotification}
        onChange={() => handleToggleNotification(user)}
      />
    )
  }

  interface Props {
    userId: string
  }
  const DeleteUserModal = ({ userId }: Props) => {
    const [open, setOpen] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)

    const handleDeleteUser = async (userId: string) => {
      setDeleting(true)
      // Delete campaign from firestore
      await deleteUser(userId, activeAccount.id)
      // Delete campaign from Redux
      dispatch(deleteUserInData(userId))
      setDeleting(false)
      setOpen(false)
    }

    return (
      <>
        <S.IconContainer onClick={() => setOpen(true)}>
          <FaTrash />
        </S.IconContainer>
        <Modal centered show={open} onHide={() => setOpen(false)}>
          <Modal.Body>
            <Modal.Title>
              Are you sure you want to delete this user?
            </Modal.Title>
            <Modal.Body>
              If you delete this user you cannot recreate it.
            </Modal.Body>
            <Modal.Footer>
              <span className="link mr-2" onClick={() => setOpen(false)}>
                Close
              </span>
              <ReelerButton
                loading={deleting}
                dispatch={() => handleDeleteUser(userId)}
                text="Delete user"
                styleClass="btn-delete"
              />
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <S.TableContainer maxHeight="500px" minHeight="82px">
      <S.StyledTable>
        <S.TableHead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">ID</th>
            <th scope="col">Role</th>
            <th scope="col" className="">
              Created
            </th>
            <th scope="col">Last active</th>
            <th scope="col d-flex justify-content-center">Weekly email</th>
            <th scope="col" className="col-1"></th>
          </tr>
        </S.TableHead>

        {filteredAccounts.length !== 0 ? (
          <tbody>
            {filteredAccounts?.map((user, i) => {
              const createdAt =
                user.createdAt && new Date(user.createdAt._seconds * 1000)

              const lastActive =
                user.latestActivity &&
                new Date(user.latestActivity._seconds * 1000)

              console.log(user)

              return (
                <tr className="fw-normal" key={i}>
                  <th>
                    <span className="ms-2">{user.email}</span>
                  </th>
                  <td className="align-middle">
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(user.id)
                      }}
                    >
                      <CopyIDIcon />
                    </span>
                  </td>
                  <td className="align-middle">
                    <span>{user.role}</span>
                  </td>
                  <td className="align-middle">
                    <span>
                      {createdAt ? createdAt.toLocaleDateString("sv-SE") : null}
                    </span>
                  </td>
                  <td className="align-middle">
                    {renderColoredSpan(lastActive)}
                  </td>
                  <td className="align-middle d-flex justify-content-center">
                    <ReelerTooltip text="Toggle weekly notification">
                      <ToggleUserNotfication user={user} />
                    </ReelerTooltip>
                  </td>
                  <td>
                    <DeleteUserModal userId={user.id} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr className="fw-normal">
              <th>
                <span className="ms-2">(No Users)</span>
              </th>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        )}
      </S.StyledTable>
    </S.TableContainer>
  )
}
