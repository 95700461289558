import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { rootState } from "./store"

export interface IAccounts {
  id: string
  userId?: string
  name: string
  totalAssets?: number
  plan: string
  integrations?: {
    instagram: {
      page_name: string
      createdAt: {
        _seconds: number
        _nanoseconds: number
      }
    }
  }

  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
}

export interface ICampaigns {
  id: string
  accountId: string
  assets: number
  campaignName: string
  campaignType: string
  embed: boolean | null
  status: string
  hashtag: {
    id: string
    name: string
    createdAt: {
      seconds: number
      nanoseconds: number
    }
  }
  createdAt: {
    seconds: number
    nanoseconds: number
  }
}

export interface IUsers {
  id: string
  accounts: string[]
  firstName?: string
  lastName?: string
  name: string
  phone?: string
  email: string
  role: string
  notifications: {
    weeklyNotification: boolean
    dailyNotification: boolean
  }
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  latestActivity: {
    _seconds: number
    _nanoseconds: number
  }
}

export interface IFeeds {
  id: string
  accountId: string
  userId: string
  assetCount: number
  feedName: string
  feedType: string
  embedType: string
  status: string
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
}

export interface IKpis {
  createdAt: {
    seconds: number
    nanoseconds: number
  }
  dailyActiveUsers: number
}

export type dataState = {
  data: {
    accounts: IAccounts[]
    campaigns: ICampaigns[]
    users: IUsers[]
    feeds: IFeeds[]
    noOfAssetsLastMonth: number
    noOfAssetsYesterday?: number
    kpis: IKpis[]
  }
}

export interface IUpdateData {
  id: string
  account: IAccounts
}

const initialState: dataState = Object.freeze({
  data: {
    accounts: [],
    campaigns: [],
    users: [],
    feeds: [],
    noOfAssetsLastMonth: 0,
    noOfAssetsYesterday: 0,
    kpis: [],
  },
})

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateData(state, { payload }: PayloadAction<dataState>) {
      state.data = payload.data
    },
    updateAccountInData(state, { payload }: PayloadAction<IUpdateData>) {
      state.data.accounts = state.data.accounts.map(acc => {
        if (acc.id === payload.id) {
          return payload.account
        }
        return acc
      })
    },
    deleteAccountInData(state, { payload }: PayloadAction<string>) {
      state.data.accounts = state.data.accounts.filter(
        acc => acc.id !== payload
      )
    },
    deleteCampaignInData(state, { payload }: PayloadAction<string>) {
      state.data.campaigns = state.data.campaigns.filter(
        campaign => campaign.id !== payload
      )
    },
    deleteUserInData(state, { payload }: PayloadAction<string>) {
      state.data.users = state.data.users.filter(user => user.id !== payload)
    },
    updateUserInData(state, { payload }: PayloadAction<IUsers>) {
      state.data.users = state.data.users.map(u => {
        if (u.id === payload.id) {
          return payload
        }
        return u
      })
    },
  },
})

export const {
  updateData,
  updateAccountInData,
  deleteAccountInData,
  deleteCampaignInData,
  deleteUserInData,
  updateUserInData,
} = dataSlice.actions

export const selectData = (state: rootState) => state.data.data
export const selectedAccounts = (state: rootState) => state.data.data.accounts

export default dataSlice.reducer
